const range = (start, end) =>
  Array(end)
    .fill()
    .map((_x, i) => start + i)

const generateClasses = (property, values, responsive = true) => {
  const classes = []

  for (const value of values) {
    const c = `${property}-${value}`
    classes.push(c)

    if (responsive) {
      for (const screen of ['xs', 'sm', 'md', 'lg', 'xl']) {
        classes.push(`${screen}:${c}`)
      }
    }
  }

  return classes
}

const safelist = [
  ...generateClasses('text', ['left', 'center', 'right', 'justify']),
  ...generateClasses('animate', ['none', 'spin', 'ping', 'pulse', 'bounce']),
  ...generateClasses('grid-cols', [...range(1, 12), 'none']),
  ...generateClasses('col-span', [...range(1, 12), 'full']),
  ...generateClasses('col-start', [...range(1, 13), 'auto']),
  ...generateClasses('order', [...range(1, 12), 'first', 'last', 'none']),
  ...generateClasses('self', ['auto', 'start', 'end', 'center', 'stretch']),
  ...generateClasses('justify-self', [
    'auto',
    'start',
    'end',
    'center',
    'stretch',
  ]),
  ...generateClasses('max-w', [
    '0',
    'none',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    '2xl',
    '3xl',
    '4xl',
    '5xl',
    '6xl',
    '7x',
    'full',
    'min',
    'max',
    'prose',
    'screen-sm',
    'screen-md',
    'screen-lg',
    'screen-xl',
    'screen-2xl',
  ]),
  ...generateClasses('min-h', ['screen', 'xs', 'sm', 'md', 'lg', 'xl']),
  ...generateClasses('pt', [
    '[100%]',
    '[calc((3/4)*100%)]',
    '[calc((7/5)*100%)]',
    '[calc((6/4)*100%)]',
    '[calc((9/16)*100%)]',
    '[calc((7/16)*100%)]',
  ]),
]

const screens = {
  xs: '480px',
  sm: '768px',
  md: '940px',
  lg: '1200px',
  xl: '1600px',
}

const getColors = () => {
  const colors = {
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000000',
    white: '#FFFFFF',
    gray: {
      light: '#969696',
      DEFAULT: '#303032',
    },
    orange: '#D55846',
    red: {
      light: '#FFEEF1',
      DEFAULT: '#F52D4C',
    },
    green: {
      light: '#C7FFDD',
      DEFAULT: '#00AB44',
      dark: '#1D5C37',
    },
  }

  return {
    ...colors,
    pageBG: colors.white,
    pageText: colors.black,
    header: {
      bg: colors.white,
      text: colors.black,
    },
    box: {
      bg: colors.pageBG,
      border: colors.pageText,
    },
    btn: {
      primary: {
        text: {
          DEFAULT: colors.white,
          hover: colors.white,
        },
        bg: {
          DEFAULT: colors.orange,
          hover: colors.orange,
        },
        border: {
          DEFAULT: colors.orange,
          hover: colors.orange,
        },
      },
      secondary: {
        text: {
          DEFAULT: colors.orange,
          hover: colors.white,
        },
        bg: {
          DEFAULT: colors.transparent,
          hover: colors.orange,
        },
        border: {
          DEFAULT: colors.orange,
          hover: colors.orange,
        },
      },
    },
    input: {
      border: colors.black,
      text: colors.black,
      bg: colors.transparent,
      placeholder: 'rgba(0, 0, 0, 0.4)',
      inverted: {
        border: colors.white,
        text: colors.white,
        bg: colors.transparent,
        placeholder: 'rgba(255, 255, 255, 0.4)',
      },
    },
    success: {
      DEFAULT: colors.green.DEFAULT,
      bg: colors.green.light,
    },
    error: {
      DEFAULT: colors.red.DEFAULT,
      bg: colors.red.light,
    },
  }
}

module.exports = {
  safelist,
  screens,
  colors: getColors(),
}
