import { BaseSyntheticEvent, useContext, useState } from 'react'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import BlockContent from '@sanity/block-content-to-react'

import { HasAuthStrings } from '@data/sanity/queries/types/site'
import { ParseStatus } from '@lib/shopify/graphql/client'
import { createUser } from '@lib/shopify/graphql/customer'
import { SignupFormValues, useLoginUser } from '@lib/auth'
import { triggerFacebookEvent, FacebookEvent } from '@lib/facebook'
import { ErrorMessages } from '@lib/helpers'
import { LanguageContext } from '@lib/language'
import { getPageUrl, PageType } from '@lib/routes'
import { serializers } from '@lib/serializers'
import { SiteContext } from '@lib/site'
import { ShopContext } from '@lib/shop'
import { StringsContext } from '@lib/strings'

import Alert from '@components/alert'
import Button, { ButtonVariant } from '@components/buttons/button'
import InputField from '@components/input-field'

interface SignupFormProps extends HasAuthStrings {
  className?: string
}

const SignupForm = ({ authStrings, className }: SignupFormProps) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<SignupFormValues>()
  const { locale } = useContext(LanguageContext)
  const strings = useContext(StringsContext)
  const { settings } = useContext(SiteContext)
  const router = useRouter()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({})
  const loginUser = useLoginUser()
  const { shopifyStorefrontClient } = useContext(ShopContext)

  // Handle form submission
  const onSubmit = async (
    values: SignupFormValues,
    event?: BaseSyntheticEvent
  ) => {
    event?.preventDefault()

    if (!shopifyStorefrontClient) {
      throw new Error('Shopify Storefront API client missing')
    }

    setIsLoading(true)
    setIsError(false)

    // Create user
    const createUserResult = await createUser(shopifyStorefrontClient, values)
    setErrorMessages(createUserResult.fieldErrors)

    if (createUserResult.status !== ParseStatus.OK) {
      setIsError(true)
    }

    if (
      createUserResult.status === ParseStatus.OK &&
      createUserResult.errorCount === 0
    ) {
      const { email, password } = values

      if (settings?.facebookEvents) {
        await triggerFacebookEvent(FacebookEvent.COMPLETE_REGISTRATION, locale)
      }

      // Login after creating user
      const loginUserResult = await loginUser({
        email,
        password,
      })

      setErrorMessages(loginUserResult.fieldErrors)

      if (loginUserResult.status !== ParseStatus.OK) {
        setIsError(true)
      }

      if (
        loginUserResult.status === ParseStatus.OK &&
        loginUserResult.errorCount === 0
      ) {
        reset()

        // Redirect to account after logging in
        router.push(getPageUrl(PageType.ACCOUNT_PAGE))
      }
    }

    setIsLoading(false)
  }

  const firstNameRegister = register('firstName', {
    required: authStrings.signupFirstNameMissing,
  })
  const lastNameRegister = register('lastName', {
    required: authStrings.signupLastNameMissing,
  })
  const emailRegister = register('email', {
    required: authStrings.signupEmailMissing,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: strings.emailInvalid,
    },
  })
  const passwordRegister = register('password', {
    required: authStrings.signupPasswordMissing,
  })

  const isDisabled =
    !!errors.firstName ||
    !!errors.lastName ||
    !!errors.email ||
    !!errors.password

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <InputField
        id="signup-fist-name"
        type="text"
        formRegister={firstNameRegister}
        errorMessage={errorMessages?.firstName ?? errors.firstName?.message}
        label={authStrings.signupFirstName}
        placeholder={authStrings.signupFirstNamePlaceholder}
        className="mb-4"
      />

      <InputField
        id="signup-last-name"
        type="text"
        formRegister={lastNameRegister}
        errorMessage={errorMessages?.lastName ?? errors.lastName?.message}
        label={authStrings.signupLastName}
        placeholder={authStrings.signupLastNamePlaceholder}
        className="mb-4"
      />

      <InputField
        id="signup-email"
        type="email"
        autoComplete="email"
        formRegister={emailRegister}
        errorMessage={errorMessages?.email ?? errors.email?.message}
        label={authStrings.signupEmail}
        placeholder={authStrings.signupEmailPlaceholder}
        className="mb-4"
      />

      <InputField
        id="signup-password"
        type="password"
        autoComplete="off"
        formRegister={passwordRegister}
        errorMessage={errorMessages?.password ?? errors.password?.message}
        label={authStrings.signupPassword}
        placeholder={authStrings.signupPasswordPlaceholder}
        className="mb-4"
      />

      {isError && (
        <div key="error" className="mt-8">
          <Alert error>
            <BlockContent
              renderContainerOnSingleChild
              className="rc rc-error"
              blocks={authStrings.signupErrorMessage}
              serializers={serializers}
            />
          </Alert>
        </div>
      )}

      <div className="flex justify-center mt-8">
        <Button
          type="submit"
          variant={ButtonVariant.PRIMARY}
          disabled={isLoading || isDisabled}
          className="min-w-[260px]"
        >
          {isLoading ? strings.buttonSubmitting : authStrings.signupSubmit}
        </Button>
      </div>
    </form>
  )
}

export default SignupForm
