import { BaseSyntheticEvent, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { m, AnimatePresence } from 'framer-motion'
import BlockContent from '@sanity/block-content-to-react'

import { ParseStatus } from '@lib/shopify/graphql/client'
import { recoverUserPassword } from '@lib/shopify/graphql/customer'
import { HasAuthStrings } from '@data/sanity/queries/types/site'
import { fadeAnimation } from '@lib/animate'
import { PasswordRecoveryFormValues } from '@lib/auth'
import { ErrorMessages } from '@lib/helpers'
import { serializers } from '@lib/serializers'
import { ShopContext } from '@lib/shop'
import { StringsContext } from '@lib/strings'

import Alert from '@components/alert'
import Button, { ButtonVariant } from '@components/buttons/button'
import InputField from '@components/input-field'

interface PasswordRecoveryFormProps extends HasAuthStrings {
  className?: string
}

const PasswordRecoveryForm = ({
  authStrings,
  className,
}: PasswordRecoveryFormProps) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<PasswordRecoveryFormValues>()
  const strings = useContext(StringsContext)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({})
  const [isLoading, setIsLoading] = useState(false)
  const { shopifyStorefrontClient } = useContext(ShopContext)

  // Handle form submission
  const onSubmit = async (
    values: PasswordRecoveryFormValues,
    event?: BaseSyntheticEvent
  ) => {
    event?.preventDefault()

    if (!shopifyStorefrontClient) {
      throw new Error('Shopify Storefront API client missing')
    }

    setIsLoading(true)
    setIsError(false)

    const recoverUserPasswordResult = await recoverUserPassword(
      shopifyStorefrontClient,
      values
    )
    setErrorMessages(recoverUserPasswordResult.fieldErrors)

    if (recoverUserPasswordResult.status !== ParseStatus.OK) {
      setIsError(true)
    }

    if (
      recoverUserPasswordResult.status === ParseStatus.OK &&
      recoverUserPasswordResult.errorCount === 0
    ) {
      reset()
      setIsSuccess(true)
    }

    setIsLoading(false)
  }

  const emailRegister = register('email', {
    required: authStrings.signupEmailMissing,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: strings.emailInvalid,
    },
  })

  const isDisabled = !!errors.email

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <AnimatePresence exitBeforeEnter>
        {isSuccess && (
          <m.div
            key="success"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <Alert success>
              <BlockContent
                renderContainerOnSingleChild
                className="rc rc-success"
                blocks={authStrings.passwordRecoverySuccessMessage}
                serializers={serializers}
              />
            </Alert>
          </m.div>
        )}

        {!isSuccess && (
          <m.div
            key="form"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <InputField
              id="signup-email"
              type="email"
              autoComplete="email"
              formRegister={emailRegister}
              errorMessage={errorMessages?.email ?? errors.email?.message}
              label={authStrings.signupEmail}
              placeholder={authStrings.signupEmailPlaceholder}
            />

            {isError && (
              <div key="error" className="mt-8">
                <Alert error>
                  <BlockContent
                    renderContainerOnSingleChild
                    className="rc rc-error"
                    blocks={authStrings.passwordRecoveryErrorMessage}
                    serializers={serializers}
                  />
                </Alert>
              </div>
            )}

            <div className="flex justify-center mt-8">
              <Button
                type="submit"
                variant={ButtonVariant.PRIMARY}
                disabled={isLoading || isDisabled}
                className="min-w-[260px]"
              >
                {isLoading
                  ? strings.buttonSubmitting
                  : authStrings.passwordRecoverySubmit}
              </Button>
            </div>
          </m.div>
        )}
      </AnimatePresence>
    </form>
  )
}

export default PasswordRecoveryForm
