import {
  Accordion,
  Accordions,
  AccountAddressDetails,
  AccountDetails,
  AccountOrderList,
  AccountProductList,
  CollectionCarousel,
  Freeform,
  LoginForm,
  Newsletter,
  PasswordRecoveryForm,
  ProductCard,
  SignupForm,
  Video,
  ProductBundleForm,
  BlogPostCard,
  ContentCarousel,
} from '@data/sanity/schema'
import { SanityBlogPostWithoutContent, SanityBlogPostOptions } from './blog'
import { SanityContentFragment } from './content'
import { SanityImageFragment } from './image'
import { SanityProductBundle, SanityProductFragment } from './product'
import { HasAccountStrings, HasAuthStrings } from './site'
import { SanityVideo } from './video'
import { Keyed } from '@lib/helpers'

export enum SanityBlockType {
  FREEFORM = 'freeform',
  ACCORDIONS = 'accordions',
  PRODUCT_CARD = 'productCard',
  SIGNUP_FORM = 'signupForm',
  LOGIN_FORM = 'loginForm',
  PASSWORD_RECOVERY_FORM = 'passwordRecoveryForm',
  ACCOUNT_DETAILS = 'accountDetails',
  ACCOUNT_ADDRESS_DETAILS = 'accountAddressDetails',
  ACCOUNT_PRODUCT_LIST = 'accountProductList',
  ACCOUNT_ORDER_LIST = 'accountOrderList',
  VIDEO = 'video',
  COLLECTION_CAROUSEL = 'collectionCarousel',
  NEWSLETTER = 'newsletter',
  PRODUCT_BUNDLE_FORM = 'productBundleForm',
  BLOG_POST_CARD = 'blogPostCard',
  CONTENT_CAROUSEL = 'contentCarousel',
}

export type SanityFreeformBlock = Keyed<
  Pick<Freeform, '_type' | 'textAlign' | 'maxWidth'> & {
    content: Array<SanityContentFragment>
  }
>

export type SanityFreeform = Omit<SanityFreeformBlock, '_type' | '_key'>

export type SanityAccordionsBlockItem = Pick<Accordion, 'title'> & {
  id: string
  content: Array<SanityContentFragment>
}

export type SanityAccordionsBlock = Keyed<
  Pick<Accordions, '_type'> & {
    items: Array<SanityAccordionsBlockItem>
  }
>

export type SanityAccordions = Omit<SanityAccordionsBlock, '_type' | '_key'>

export type SanityProductCardBlock = Keyed<
  Pick<ProductCard, '_type'> & {
    product: SanityProductFragment
  }
>

export type SanityProductCard = Omit<SanityProductCardBlock, '_type' | '_key'>

export type SanitySignupFormBlock = Keyed<SignupForm & HasAuthStrings>

export type SanityLoginFormBlock = Keyed<LoginForm & HasAuthStrings>

export type SanityPasswordRecoveryFormBlock = Keyed<
  PasswordRecoveryForm & HasAuthStrings
>

export type SanityAccountDetailsBlock = Keyed<
  AccountDetails & HasAccountStrings
>

export type SanityAccountAddressDetailsBlock = Keyed<
  AccountAddressDetails & HasAccountStrings
>

export type SanityAccountProductListBlock = Keyed<
  AccountProductList & HasAccountStrings
>

export type SanityAccountOrderListBlock = Keyed<
  AccountOrderList & HasAccountStrings
>

export type SanityVideoBlock = Keyed<Pick<Video, '_type'> & SanityVideo>

export type SanityCollectionCarouselBlock = Keyed<
  Pick<CollectionCarousel, '_type'> & {
    collection: {
      products: Array<SanityProductFragment>
    }
  }
>

export type SanityCollectionCarousel = Omit<
  SanityCollectionCarouselBlock,
  '_type' | '_key'
>

export type SanityNewsletterBlock = Keyed<
  Pick<Newsletter, '_type' | 'klaviyoListID' | 'submit'> & {
    successMsg: Array<SanityContentFragment>
    errorMsg: Array<SanityContentFragment>
    terms: Array<SanityContentFragment>
  }
>

export type SanityProductBundleFormBlock = Keyed<
  Pick<ProductBundleForm, '_type' | 'showGallery'> & {
    productBundle: SanityProductBundle
  }
>

export type SanityBlogPostCardBlock = Keyed<
  Pick<BlogPostCard, '_type'> & {
    post: SanityBlogPostWithoutContent
    options?: SanityBlogPostOptions
  }
>

export enum SanityContentCarouselItemType {
  FREEFORM = 'freeform',
  PHOTO = 'photo',
  PRODUCT = 'product',
  BLOG_POST = 'blogPost',
  VIDEO = 'video',
}

type SanityContentCarouselFreeformItem = {
  _type: SanityContentCarouselItemType.FREEFORM
  freeform: SanityFreeformBlock
}

type SanityContentCarouselPhotoItem = {
  _type: SanityContentCarouselItemType.PHOTO
  photo: SanityImageFragment
}

type SanityContentCarouselProductItem = {
  _type: SanityContentCarouselItemType.PRODUCT
  product: SanityProductFragment
}

type SanityContentCarouselBlogPostItem = {
  _type: SanityContentCarouselItemType.BLOG_POST
  blogPost: SanityBlogPostWithoutContent
}

type SanityContentCarouselVideoItem = {
  _type: SanityContentCarouselItemType.VIDEO
  video: SanityVideo
}

export type SanityContentCarouselItem = Keyed<
  | SanityContentCarouselFreeformItem
  | SanityContentCarouselPhotoItem
  | SanityContentCarouselProductItem
  | SanityContentCarouselBlogPostItem
  | SanityContentCarouselVideoItem
>

export interface SanityContentCarousel {
  items: Array<SanityContentCarouselItem>
}

export type SanityContentCarouselBlock = Keyed<
  Pick<ContentCarousel, '_type'> & SanityContentCarousel
>

export type SanityBlocks =
  | SanityFreeformBlock
  | SanityAccordionsBlock
  | SanityProductCardBlock
  | SanitySignupFormBlock
  | SanityLoginFormBlock
  | SanityPasswordRecoveryFormBlock
  | SanityAccountDetailsBlock
  | SanityAccountAddressDetailsBlock
  | SanityAccountProductListBlock
  | SanityAccountOrderListBlock
  | SanityVideoBlock
  | SanityCollectionCarouselBlock
  | SanityNewsletterBlock
  | SanityProductBundleFormBlock
  | SanityBlogPostCardBlock
  | SanityContentCarouselBlock
