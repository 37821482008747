import { ChangeEvent, FocusEvent, useState } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import cx from 'classnames'

import Icon from './icon'

export interface DropdownOption {
  value: string
  title: string
}

interface InputDropdownProps {
  id: string
  formRegister: UseFormRegisterReturn
  options: DropdownOption[]
  errorMessage?: string
  label?: string
  defaultValue?: string
  placeholder?: string
  borderBottom?: boolean
  className?: string
  isInvertedColors?: boolean
}

const InputDropdown = ({
  id,
  formRegister,
  options,
  errorMessage,
  label,
  defaultValue,
  placeholder,
  borderBottom,
  className,
  isInvertedColors,
}: InputDropdownProps) => {
  const [value, setValue] = useState(defaultValue)

  return (
    <div className={cx('grid', className)}>
      <div className={cx('flex flex-col relative text-left')}>
        {label && (
          <label htmlFor={id} className="text-xs mb-2">
            {label}
          </label>
        )}

        <div
          className={cx(
            'relative appearance-none w-full h-full py-2',
            'text-base leading-none',
            {
              'bg-input-bg text-input-text placeholder-input-placeholder':
                !isInvertedColors,
              'bg-input-inverted-bg text-input-inverted-text placeholder-input-inverted-placeholder':
                isInvertedColors,
              'border-error': errorMessage,
              'border-input-border': !errorMessage && !isInvertedColors,
              'border-input-inverted-border': !errorMessage && isInvertedColors,
              'border-b': borderBottom,
              'border px-4': !borderBottom,
            }
          )}
        >
          <select
            id={id}
            ref={formRegister.ref}
            name={formRegister.name}
            placeholder={placeholder}
            className="absolute block top-0 left-0 w-full h-full opacity-0 z-10 cursor-pointer focus:outline-none"
            value={value}
            onBlur={(event: FocusEvent<HTMLSelectElement>) => {
              formRegister.onBlur(event)
              setValue(event.target.value)
            }}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              formRegister.onChange(event)
              setValue(event.target.value)
            }}
            aria-label={label}
          >
            <option value="">{placeholder}</option>
            {options.map(({ value, title }) => (
              <option key={value} value={value}>
                {title}
              </option>
            ))}
          </select>

          <span className="flex justify-between items-center pointer-events-none text-base relative pr-6">
            {options.find((option) => option.value === value)?.title ?? (
              <span
                className={cx({
                  'text-input-placeholder': !isInvertedColors,
                  'text-input-inverted-placeholder': isInvertedColors,
                })}
              >
                {placeholder}
              </span>
            )}
            <span className="inline-flex items-center ml-2 absolute inset-y-0 right-0">
              <Icon
                id="input-dropdown"
                name="ChevronDown"
                className="block h-[1rem]"
                color="text-current"
              />
            </span>
          </span>
        </div>

        {errorMessage && (
          <span role="alert" className="mt-2 text-xs text-error">
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  )
}

export default InputDropdown
